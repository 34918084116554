import cloneDeep from "lodash/cloneDeep";
import gql from "graphql-tag";
import { download } from "@/documents";

/**
 * Handles an error message.
 * - Displayed as an alert and rejects the promise.
 * @param {String} message The error message.
 * @return {*}
 */
function handleError(message) {
  vm.showMessage("error", message);
  return Promise.reject(message);
}

/**
 * State of the Store DIE module.
 * @type {Object}
 */
const DIEState = {
  selectedIngestionProcess: "",
  selectedDocumentId: "",
  selectedSchemaId: "",
  recipeModal: {
    display: false,
  },
};

/**
 * Mutations for the Store DIE module.
 * @type {Object}
 */
const DIEMutations = {
  /**
   * Resets the state to its default values.
   * @param {Object} state The current state of the module.
   */
  RESET_STATE(state) {
    Object.assign(state, cloneDeep(DIEState));
  },
  SET_CONFIRM_DIALOG(state, dialog) {
    state.confirmDialog = dialog;
  },
  SET_CONFIRM_DIALOG_VISIBLE(state, visible) {
    state.confirmDialog.show = visible;
  },
  SET_SELECTED_INGESTION_PROCESS(state, ip) {
    state.selectedIngestionProcess = ip;
  },
  SET_SELECTED_DOCUMENT_ID(state, id) {
    state.selectedDocumentId = id;
  },
  SET_RECIPE_MODAL_DISPLAY(state, display) {
    state.recipeModal.display = display;
  },
  SET_SELECTED_SCHEMA_ID(state, id) {
    state.selectedSchemaId = id;
  },
};

/**
 * Actions for the Store DIE module.
 * @type {Object}
 */
const DIEActions = {
  async downloadVersion({ commit, rootState }, payload) {
    if (!payload) return;

    const { ip, version } = payload;

    if (!['original', 'latest'].includes(version)) {
      return handleError("Invalid version");
    }

    if (["PENDING", "RUNNING"].includes(ip.status)) {
      return handleError("Unable to download while in processing");
    }

    const selectedIngestionProcess = cloneDeep(ip);
    commit("SET_SELECTED_INGESTION_PROCESS", selectedIngestionProcess);

    const docVersion = ip.rootDocument.versions[version === 'latest' ? ip.rootDocument.versions.length - 1 : 0];
    commit("SET_SELECTED_DOCUMENT_ID", docVersion.id);

    if (version === 'original' || ["UNSUPPORTED_FORMAT", "ERROR_JOB", "ERROR_DQ"].includes(ip.status)) {
      const url = `${rootState.settings.apiGatewayBase}/docs/${docVersion.id}`;

      try {
        const response = await vm.$http.get(url);
        const parsedJson = await response.json();

        const fileName = `${ip.rootDocument.name}.${docVersion.ext}`;
        download(parsedJson, fileName);

        return "Downloaded";

      } catch (error) {
        console.error("Download failed", error);
        handleError("Download failed");
        throw error;
      }
    } else {
      return "Download";
    }
  },

  async changeStatusToUploaded({ state, commit }, { ip }) {
    try {
      const response = await vm.$apollo.mutate({
        mutation: gql`
          mutation Mutation($ingestionProcessId: ID!) {
            setIngestionProcessUploaded(ingestionProcessId: $ingestionProcessId)
          }
        `,
        variables: {
          ingestionProcessId: ip.id,
        },
      });

      vm.showMessage("success", `Document ${ip.rootDocument.name} status changed to uploaded`);
      return response;

    } catch (error) {
      console.error(error);
      vm.showMessage("error", `Document ${ip.rootDocument.name} status failed to change to uploaded`);
    }
  },

  async deleteIngestionProcess({ state, commit }, { ip }) {
    try {
      const response = await vm.$apollo.mutate({
        mutation: gql`
          mutation Mutation($ingestionProcessId: ID!) {
            deleteIngestionProcess(ingestionProcessId: $ingestionProcessId)
          }
        `,
        variables: {
          ingestionProcessId: ip.id,
        },
      });
      vm.showMessage("success", `Document ${ip.rootDocument.name} deleted`);
      return response;

    } catch (error) {
      console.error(error);
      vm.showMessage("error", `Document ${ip.rootDocument.name} failed to delete`);
      throw error;
    }
  },

  async deleteLatestDocument({ state, commit }, { ip, latestDocInfo }) {

    try {
      const response = await vm.$apollo.mutate({
        mutation: gql`
          mutation Mutation($ingestionProcessId: ID, $latestDocumentId: ID) {
            deleteLatestDocumentFromIngestionProcess(ingestionProcessId: $ingestionProcessId, latestDocumentId: $latestDocumentId)
          }
        `,
        context: {
          headers: { "X-Yagro-Group-UUID": ip.rootDocument.owner.id },
        },
        variables: {
          ingestionProcessId: ip.id,
          latestDocumentId: latestDocInfo.id,
        },
      });

      vm.showMessage("success", `Latest version of ${ip.rootDocument.name} deleted`);
      return response;

    } catch (error) {
      console.error(error);
      vm.showMessage("error", `Latest version of ${ip.rootDocument.name} failed to delete`);
      throw error;
    }
  },
  async resetIngestionProcess({ state, commit }, { ip }) {
    try {
      const response = await vm.$apollo.mutate({
        mutation: gql`
          mutation Mutation($ingestionProcessId: ID!) {
            resetIngestionProcess(ingestionProcessId: $ingestionProcessId)
          }
        `,
        variables: {
          ingestionProcessId: ip.id,
        },
      });

      vm.showMessage("success", `Document ${ip.rootDocument.name} reset, and recipes set to run`);
      return response;

    } catch (error) {
      vm.showMessage("error", `Document ${ip.rootDocument.name} Unable to reset`);
      throw error;
    }
  },

  async forwardIngestionProcess({ state, commit }, { ip }) {
    try {
      const response = await vm.$apollo.mutate({
        mutation: gql`
          mutation Mutation($ingestionProcessId: ID!) {
            forwardIngestionDocToDie(ingestionProcessId: $ingestionProcessId)
          }
        `,
        variables: {
          ingestionProcessId: ip.id,
        },
      });

      vm.showMessage("success", `Document ${ip.rootDocument.name} sent to Stage 2`);
      return response;

    } catch (error) {
      vm.showMessage("error", `Document ${ip.rootDocument.name} Unable to send to Stage 2`);
      throw error;
    }
  },

  async displayRecipeModal({ state, commit }, { ip }) {
    console.log("displayRecipeModal", ip);
    if (!(ip.status.includes('ERROR') || ip.status === 'COMPLETE')) {
      vm.showMessage("error", "Unable to run recipe until current job has finished");
      return;
    }

    await commit("SET_SELECTED_SCHEMA_ID", ip.schema.id);

    const latestDocVersion = ip.rootDocument.versions[ip.rootDocument.versions.length - 1];

    commit("SET_SELECTED_INGESTION_PROCESS", ip);
    commit("SET_SELECTED_DOCUMENT_ID", latestDocVersion.id);
    commit("SET_RECIPE_MODAL_DISPLAY", true);
  },
};

/**
 * Getters for the Store DIE module.
 * @type {Object}
 */
const DIEGetters = {};

/**
 * Vuex module for user-related data and operations.
 * @type {Object}
 */
const StoreDIEModule = {
  namespaced: true,
  state: DIEState,
  getters: DIEGetters,
  mutations: DIEMutations,
  actions: DIEActions,
};

export default StoreDIEModule;
