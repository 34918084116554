let labmacRoutes = [
  {
    path: "/farm/field-operations",
    component: () => import('@/pages/farm/labmac/FieldOperations'),
    name: "Field Operations",
  },
  {
    path: "/farm/insights",
    component: () => import('@c/Farm/PointOfInterestFarm'),
    name: "Insights",
  },
  {
    path: "/farm/data/surveys",
    component: () => import('@/pages/farm/data/SurveysDashboard'),
    name: "Surveys",
  },
  {
    path: "/farm/data/survey/:surveyId?",
    component: () => import('@/pages/farm/data/FarmSurveys'),
    name: "Survey",
  },
  {
    path: "/farm/data/supply/contracts",
    component: () => import('@/pages/farm/data/FarmSupplyContracts'),
    name: "Farm Supply Contracts",
  },
  {
    path: "/farm/data/supply/contracts/:contractId/emissions",
    component: () => import('@/pages/farm/data/FarmEmissions'),
    name: "Farm Emissions",
  },
  {
    path: "/manager/fixed-costs",
    component: () => import('@/pages/admin/labmac/FixedCosts'),
    name: "Fixed Costs",
    meta: { adminOnly: true },
  },
  {
    path: "/farm/analytics/profit-loss",
    component: () => import('@/pages/farm/labmac/ProfitLoss'),
    name: "Crop Profit & Loss",
  },
];

export default labmacRoutes;
