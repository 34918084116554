import store from "@/stores";
import gql from "graphql-tag";

export function getDownloadUrl(doc_id) {
  if (doc_id) {
    return `${store.state.settings.apiGatewayBase}/docs/download/${doc_id}`;
  }
  return null;
}

export function download(doc, name) {
  let url = getDownloadUrl(doc.id) + "?redirect=False";

  window.vm.$http.get(url).then((response) =>
    response.json(),
  ).then(parsed_json => {
    const link = document.createElement("a");
    link.href = parsed_json.location;
    if (name === undefined) {
      name = `${doc.name}-${doc.id}.${doc.ext}`
    }
    link.setAttribute("download", name);
    document.body.appendChild(link);
    link.click();
  });
}


export function triggerDownloadOfIngestionContents(columns, contents, name) {
  let csv = '';
  for (let colName of columns) {
    csv += `"${colName}",`
  }

  csv = csv.replace(/(^,)|(,$)/g, '');
  csv += "\n";

  contents.forEach((row) => {
    let csvRow = '';
    for (let colName of columns) {
      if (row[colName]) {
        csvRow += `"${row[colName]}",`
      } else {
        csvRow += `"",`
      }
    }
    csvRow = csvRow.replace(/(^,)|(,$)/g, '');
    csvRow += "\n";
    csv += csvRow;
  });

  const anchor = document.createElement('a');
  anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
  anchor.target = '_blank';
  anchor.download = name
  anchor.click();
}


export function downloadIngestionDoc(apollo, docId) {
  let query = gql`query Query($documentId: ID) {
  readIngestionDocument(documentId: $documentId) {
    documentId
    columns
    jsonFile
    percentageOfDerivedValuesInColumns {
      column
      percentage
    }
  }
}`
  let result = apollo.query({
    query: query, variables: { "documentId": docId },
  }).then((response) => {
    if (response.errors) {
      let error = response.errors[0].message;
      return Promise.reject(error);
    }
    return Promise.resolve(response.data.readIngestionDocument);
  });

  return result;
}
