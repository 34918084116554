export default {
  config: {
    company: "YAGRO",
    tabTitle: "YAGRO",
    dataEmailAddress: "data@yagro.com",
  },
  general: {
    add: "Add",
    user: "User",
    business: "Company",
    copyright: "© {year} - YAGRO Ltd.",
    above: "Above",
    actions: "Actions",
    amount: "Quantity",
    all: "All",
    below: "Below",
    buy: "Buy",
    cancel: "Cancel",
    comingSoon: "Coming soon",
    ok: "OK",
    city: "City",
    chosenAmount: "Select the quantity",
    collectStart: "Collect Start",
    collectEnd: "Collect End",
    comment: "Comment",
    country: "Country",
    create: "Create",
    cost: "Cost | Costs",
    dropdownSelect: "Select",
    edit: "Edit",
    editContent: "Edit content",
    expires: "Expires",
    expiryDate: "Expiry Date",
    export: "Export",
    imageCourtesyOf: "Image Courtesy of",
    harvestYear: "Harvest Year",
    minimumAmount: "Minimum Quantity",
    na: "N/A",
    no: "No",
    offer: "Offer",
    perTonne: "per tonne",
    purchase: "Purchase",
    previousPage: "Previous page",
    proposedAmount: "Proposed Quantity",
    price: "Price",
    product: "Product",
    reset: "Reset",
    sale: "Sale | Sales",
    save: "Save",
    searchHere: "Search for a specific product",
    sell: "Sell",
    showFilters: "Show filters",
    state: "State",
    tonne: "mt",
    tonnes: "mts",
    total: "Total",
    trade: "Trade",
    tradedAt: "Traded At",
    type: "Type",
    update: "Update",
    view: "View",
    warning: "warning | Warning",
    yes: "Yes",
  },
  addEditBudgetModal: {
    actualFixedCostsTitle: "Actual Fixed Costs",
    actualFixedCostsCopyText:
      "Copy information from the budgeted fixed table above",
    alertWarning:
      "Changing the area value will affect your fixed costs budget. Would you like to continue?",
    budgetsHypen: "Budgets - ",
    budgetedPrice: "Budgeted Price",
    budgetedSales: "Budgeted Sales",
    budgetedBreakEven: "Budgeted Break Even",
    calculating: "Calculating...",
    cellEditInfo: "* Click inside a cell to add or edit tables",
    unsavedChanges: "Unsaved changes",
    cropBudget: "Crop Budget",
    farmTotal: "Farm Total",
    fixedCosts: "Fixed Costs",
    setValOnAv: "Set values based on your 3-year average",
    save: "Save",
    saveConfirmation: "save-confirmation",
    totalFixedCosts: "Total Fixed Costs",
    variableCosts: "Variable Costs",
    warningAreaRequired:
      "No area available. Please add area in the variable costs table.",
    threeYearAvgBudgetSuccess:
      "Ready! Your budget was calculated using your historical data.",
    threeYearAvgBudgetFail:
      "Sorry, we could not calculate a budget for you. You have no historical data for this crop.",
    threeYearAvgBudgetPartial:
      "We've populated your budget where historical data was available. Please fill in the remaining details.",
  },
  boxPlotModal: {
    minimumExcludingOutliers: "Minimum (excluding outliers):",
    maximumExcludingOutliers: "Maximum (excluding outliers):",
    twentyFithPercentile: "25th Percentile (Q1):",
    seventyFithPercentile: "75th Percentile (Q3):",
    median: "Median:",
    yourFarmAverage: "Your farm average:",
    understandingBoxPlot: "Understanding the boxplot",
    understandingBoxPlotPoints: [
      "The 'box' represents the middle 50% of the data, where most farms are situated.",
      "The green line (Q1) marks the boundary for the top 25% of data, the upper end of performance",
      "The yellow line (median) is the midpoint of the data set",
      "The red line (Q3) defines the threshold for the bottom 25% of data, the lower end of performance",
    ],
    forMoreInformation:
      "For more information on interpreting 'Box Plots', visit",
    thisKnowledgeHubArticle: "this Knowledge Hub article",
    onOurWebsite: "on our website.",
  },
  budgetSummary: {
    summary: "Summary",
    totalArea: "Total area",
    totalAreaToolTip: "The total area covered.",
    totalCost: "Total cost",
    totalCostToolTip: "The total cost to grow all crops.",
    totalSales: "Total sales",
    totalSalesToolTip: "The total expected sales for all crops.",
    budgetedGrossMargin: "Budgeted Gross Margin",
    budgetedGrossMarginToolTip:
      "The Budgeted Gross Margin is the output of all crops minus the associated variable costs.",
  },
  businessTrackerCrop: {
    cropProduction: "Crop Production",
    tracker: "Tracker",
    cropProgress: {
      title: "Crop Progress",
      costToDate: "Cost to Date",
      noSalesData: "No sales data",
      yield: "Yield",
      yourAvgPrice: "Your Avg Price",
      area: "Area",
      cropName: "Crop Name",
      percentSold: "% Sold",
      units: {
        tonne: "t",
        perTonne: "/t",
        perTonnePlainText: "per tonne",
        tonnePlainText: "tonnes",
        hectare: "ha",
        hectarePlainText: "hectares",
        perHectare: "/ha",
        perHectarePlainText: "per hectare",
        tonnesPerHectare: "t/ha",
        tonnesPerHectarePlainText: "tonnes per hectare",
      },
    },
    percentageDifferenceTitle: "Percentage Difference",
    percentageDifferenceBody:
      "Your current season vs your yield average in the last 3 seasons",
    charts: {
      toggleLegendMsg: "Toggle item on or off by clicking on the legend.",
    },
    expectedYieldModal: {
      yieldEstimatesWarning:
        "Time for some positivity; yield estimates can't be negative.",
      estimateVarieties: "Estimate specific varieties",
      tonnesPerHectare: "t/ha",
      setValuesOnThreeYearAvg: "Set values based on your 3-year average",
      calculating: "Calculating...",
      modalTitle: "Edit your expected yield",
      enterExpectedYield: "Enter your expected yield",
      noVarietiesAvailable: "No varieties available.",
      expectedYieldUpdatedVariety: "Expected yield updated based on variety.",
      expectedYieldUpdatedCrop: "Expected yield updated.",
      failedToUpdateExpectedYield: "Failed to update expected yield.",
      couldNotFetchPreviousYieldData: "Could not fetch previous yield data.",
      yieldCalculatedByCrop:
        "Ready! Your yield was calculated using your available crops historical data.",
      yieldCalculatedByVarieties:
        "Ready! Your yield was calculated using your available varieties historical data.",
      noHistoricalDataForVarieties:
        "No historical data for varieties: {varieties}",
      missingVarietyData: "Missing Variety Data",
    },
    selectDifferentYear: "Select a different year to view",
    or: "or",
    forTheYear: "for the year",
  },
  dataIngestion: {
    actions: {
      showComments: "Show comments",
      deleteAllVersions: "Delete and remove all versions",
      runRecipe: "Run recipe",
      forwardtoStage2: "Forward to stage 2",
      copyId: "Copy ID to clipboard",
      downloadOriginal: "Download original version",
      downloadLatest: "Download latest version",
      uploadNew: "Upload new version",
      resetVersion: "Reset data versions and rerun recipes",
      statusUploaded: "Change status to uploaded",
      forwardToStage2: "Forward to stage 2",
    },
    stageOneUploadData: "Stage 1 - Uploaded data",
    id: "ID:",
    originalFilename: "Original filename:",
    business: "Business:",
    type: "Type:",
    versions: "Versions",
    derivedPercentages: "Derived percentages:",
    rowsModified: "Number of rows modified:",
    name: "Name:",
    latest: "Latest",
    deleteThisVersion: "Delete this version",
    updatedAt: "Updated at:",
    showPreviousVersions: "Show previous versions",
    comments: "Comments",
    noComments: "No comments.",
    summary: "Summary",
    parentSummary: "Parent Summary",
    sourceFile: "Source File",
    filename: "Filename",
    uploadedAt: "Uploaded at",
    uploadedBy: "Uploaded by",
    status: "Status",
    processing: "Processing...",
    inStage2: "Stage 2",
    stillProcessing: "Processing {schemaType} data.",
    comeBackLater: "Come back later...",
    dataForwarded: "{schemaType} data forwarded to Stage 2.",
    viewData: "View data",
    onlyShowWithMissingOrZero: "Only show with missing or zero values",
    pageIsBeingEditedBy: "Page is being edited by",
    processingChildDocuments: "Processing child documents...",
    editedByPleaseWait: ". Please wait...",
    loading: "Loading...",
    cancelEditMode: "Cancel edit mode",
    editData: "Edit Data",
    save: "Save",
    cancel: "Cancel",
    complete: "COMPLETE",
    export: "Export",
    addColumn: "Add column",
    pageSize: "Page size:",
    seePreviousVersions: "See {count} previous versions",
    multipleRowSelect: "Multiple row select",
    multipleRowSelectActivated: "Multiple row select activated",
    jobError: "Job error",
    unsupportedFormat: "Unsupported format",
    format: "Format:",
    multiSheetFilesNotSupported: "Multi-sheet files not supported",
    dataError: "Data error",
    notSet: "Not set",
    hideProcessed: "Hide processed files",
    unsavedChanges: "Unsaved Changes",
    wishToLeaveWithoutSaving:
      "Are you sure you wish to leave without saving changes?",
    continueEditing: "Continue Editing",
    dontSave: "Don't Save",
    editModeEnabled: "Edit mode enabled",
    inEditMode: "In edit mode",
    parentJob: "Parent Job",
    preparingDownload: "Preparing to download data...",
    preparingData: "Preparing data...",
    preparingSaving: "Preparing to save data changes...",
  },
  dashboard: {
    goodMorning: "Good morning",
    goodAfternoon: "Good afternoon",
    goodEvening: "Good evening",
    helpfulLinks: "Helpful links",
    notifications: "Notifications",
    notificationsMsg:
      "Set your notification preferences and never miss an update.",
    updatePerfs: "Update preferences",
    users: "Users",
    usersMsg: "Manage access to YAGRO for your colleagues.",
    data: "Data",
    dataMsg:
      "Upload new data for processing directly using the link below, or email to data@yagro.com.",
    usersManage: "Manage users",
  },
  dataSelect: {
    selectCrop: "Select Crop",
    noOptionsFound: "No options found.",
  },
  dataTable: {
    showing: "Showing",
    to: "to",
    of: "of",
    entries: "entries",
    filteredFrom: "filtered from",
    totalEntries: "total entries",
    show: "Show",
    emptyTable: "None",
    loadingRecords: "Loading...",
    processing: "Processing...",
    search: "Search:",
    zeroRecords: "No matching records found",
    paginate: {
      first: "First",
      last: "Last",
      next: "Next",
      previous: "Previous",
    },
    aria: {
      sortAscending: ": activate to sort column ascending",
      sortDescending: ": activate to sort column descending",
    },
  },
  dateTimePicker: {
    selectDate: "Select date...",
    selectDateRange: "Select date range...",
    months: {
      shorthand: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      longhand: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
    },
    weekdays: {
      shorthand: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
      longhand: [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ],
    },
  },
  digitString: {
    zero: "0",
  },
  explorer: {
    couldNotFetchPerformanceBy: "Could not fetch performance by",
    couldNotFetchData: "Could not fetch data",
    failedToAddExtraDetails:
      "Failed to add extra details to the year selection drop down",
    filters: {
      nthCrop: "1st and 2nd wheat",
      variety: "variety",
      field: "field",
      crop: "crop",
      endUseGroup: "end-use group",
      endUseMarket: "end-use market",
    },
    performanceBy: "Performance by",
    viewProgramme: "View Programme",
    marketRange: "Market Range",
    insufficientMarketData: "Insufficient market data at the moment",
    noSalesData:
      "We have no sales data for this crop, send us data for an average sales value or input your data in the crop page.",
  },
  farmDeliveryLocations: {
    name: "Name",
    address: "Address",
    postcode: "Postcode",
    actions: "Actions",
    deliveryLocations: "Delivery Locations",
    addDeliveryLocation: "Add Delivery Location",
  },
  farmInvoiceEntities: {
    name: "Name",
    address: "Address",
    postcode: "Postcode",
    actions: "Actions",
    addInvoiceEntity: "Add invoice entity",
    invoiceEntities: "Invoice entities",
  },
  farmProfile: {
    title: "Account Details",
    brandingId: "Branding ID",
    tabTitle: "Account Details",
    companyId: "Company Registration Number (CRN)",
    stateReg: "State registration",
    farmInformation: "Farm Information",
    farmSize: "Farm Size",
    farmSizeUnit: "Unit",
    contactName: "Contact Name",
    contactRole: "Contact Role",
    contactNumber: "Contact Number",
    croppedAreainHa: "Cropped Area",
    businessName: "Business name",
    marketingInformation: "Marketing information",
    orderNotes: "Order notes",
    email: "Company Email",
    phoneNumber: "Company Phone Number",
    shortcode: "Shortcode",
    address: "Address",
    city: "City",
    postcode: "Postcode",
    country: "Country",
    contactDetails: "Contact details (order confirmation)",
    permission: "Permission to request products via",
    editInformation: "Edit information",
    search: "Search",
    show: "Show",
    entries: "entries",
    name: "Name",
    logo: "Logo",
    actions: "Actions",
    saveChanges: "Save Changes",
    analyticsAndTracker: "Analytics And Tracker",
    farmSoftwareUsed: "Farm Software Used",
    workPartners: "YAGRO Partners we work with",
    marketPlace: "Marketplace",
  },
  fieldInfoCard: {
    fieldArea: "Field area",
    fieldAreaToolTip:
      "The ground area of the field, including any that couldn't be harvested.",
    budgetedAreaToolTip: "This is the planned area from your budget.",
    fieldsWithHash: "# Fields",
    harvestedArea: "Harvested area",
    harvestedAreaToolTip:
      "The area actually harvested; which may be less than the total area of the field",
    crop: "Crop",
    previousCrop: "Previous crop",
    previousCropToolTip: "The crop grown on this field in {n}",
    noData: "No data",
    variety: "Variety",
    varieties: "Varieties",
    totalCost: "Total cost",
    totalCostToolTip: "Variable costs of products applied over the field(s).",
    costPerHa: "Cost per hectare",
    costPerHaToolTip:
      "Variable costs of products applied over the field. Note that if not all fields could be harvested 100% of their area, this will reflect the cost per field area and will not be equal to the cost of production multiplied by yield (which uses the harvested area).",
    production: "Production",
    productionToolTip: "The amount that was harvested.",
    yield: "Yield",
    yieldToolTip:
      "Tonnes produced per hectare harvested. In this case, the source of this data is: {s}",
    editExpectedYield: "Edit your expected yield",
    na: "N/A",
    costOfProduction: "Cost of production",
    costOfProductionToolTip:
      "Variable costs of products applied per tonne harvested.",
    totalBudget: "Total budget",
    totalBudgetToolTip: "Your total budget for variable costs this year.",
    budgetPerHa: "Budget per hectare",
    budgetPerHaToolTip:
      "Your budgeted costs per intended hectare. If your actual sown area differs from your budget, this will provide a more effective comparison.",
    budgetPerTonne: "Budget per tonne",
    budgetPerTonneToolTip:
      "Your budgeted costs per intended tonne harvested. If your actual yield differs significantly from your budget, this will provide a more effective comparison.",
    achieved: "achieved",
    achievedToolTip: "The total pound value of all your sales.",
    sales: "Sales",
    salesToolTip:
      "The sales value is based off the sales and contracts values you have provided or input manually.",
    grossMargin: "Gross margin",
    poundPerTonneUnit: "£/t",
    totalUnit: "£",
    totalUnitLabel: "Total £",
    grossMarginAccurateMsg:
      "* For more accurate Gross Margin results edit the varieties sales figures",
    resetAverage: "Reset to your average price",
    resetAverageMsg:
      "Click here to reset all the above values to your average £/t sales price. In the event you have not submitted any sales data we will reset to a zero value",
    tonnes: "tonnes",
  },
  manager: {
    request: "Request | Requests",
    deliveryLocations: "Delivery Locations",
    quote: "Quote | Quotes",
    order: "Order | Orders",
  },
  priceCheckList: {
    priceData: "Price Data",
    exportData: "Export data",
    addRebate: "Add Rebate",
    addPurchase: "Add Purchase",
    resetFilters: "Reset Filters",
  },
  priceCheck: {
    price: "price | prices",
    season: "season",
    sortBy: "sort by:",
    category: "category",
    subCategory: "Sub-category",
    manufacturer: "manufacturer",
    product: "product",
    model: "model",
    additionalFilters: "Additional filters",
    handed: "handed",
    aerialUse: "Aerial use",
    aquatic: "Aquatic",
    parallelImport: "Parallel import",
    formulation: "Formulation",
    lerap: "LERAP",
    level: "Level",
    cannotFind: "Can't find what you're looking for?",
    addANewProduct: "Add a new product",
    selectProductCategory: "Select product category",
    orUploadYourData: "or upload your data",
    here: "here",
    save: "save:",
    saveToolTip: "Per unit savings at market best",
    brandOpportunity: "brand opportunity:",
    notApplicableShorthand: "n/a",
    notApplicableToolTip:
      "We are still collecting price data on this product. This will display as soon as we have sufficient data to give a meaningful, anonymous picture of the market.",
    noSavings: "no savings",
    noSavingsToolTip: "This alternative costs more at best market prices",
    extra: "extra:",
    cheapestPrice: "You already have the cheapest price",
    outlierPrice:
      "Your purchase price sits within outliers, click on the chart to see more.",
  },
  prices: {
    productName: "Product Name",
    unitPrice: "Unit Price",
    fromMinimumWithPercent: "% From Minimum",
    fromMinimumWithPercentExclOutliers: "% From Minimum (excluding outliers)",
    fromMinimumWithPercentMsg:
      "This is, in percentage terms, how much you've paid above the lowest market price for this brand.",
    excludingOutliers: "(excluding outliers)",
    medianPrice: "Median Price",
    totalPrice: "Total Price",
    brandOpportunity: "This Brand Opportunity",
    brandOpportunityMsg:
      "This is the potential cost saving you can realise by purchasing this product at lowest market price. This is based on product prices only and excludes additional services such as walking fees, payment or delivery terms, or timing of purchase within the chosen period.",
    altBrandOpportunity: "Alternative Brand Opportunity",
    altBrandOpportunityMsg:
      "This is the potential cost saving achievable by choosing the lowest cost alternative product with identical active ingredients. This is based on same active, same concentration only, and does not factor in applicability to specific crop types or quality of product.",
    quantity: "Quantity",
  },
  fieldOverview: {
    soilHistory: "Soil Analysis History",
    collectionTime: "Collection Time",
    latestSoilAnalysis: "Latest soil analysis",
    type: "Type",
    fieldArea: " Field area",
    fieldName: "Field name",
    soilType: "Soil Type",
    soilTexture: "Soil Texture",
    fieldURN: "Field URN",
    moisture: "Moisture",
    ph: "ph",
    drainage: "Drainage",
    temperature: "Temperature",
    comments: "Comments",
    updated: "Updated",
    orchardSpecifics: "Orchard Specifics",
    rowSpacing: "Row spacing",
    treeSpacing: "Tree spacing",
    treeCount: "Tree count",
    growingSystem: "Growing system",
    plantedYear: "Implementation / Planted year",
    coordinates: "Coordinates and Nitrate Vulnerable Zone",
    nvz: " Is the field in NVZ ?",
    latitude: "Latitude",
    longitude: "Longitude",
    uploadCoordinates: "Upload coordinates",
    uploadShapefile: "Upload shapefile",
  },
  supplierProfile: {
    companyId: "Company Registration Number (CRN)",
    stateReg: "State registration",
    title: "Account Details",
    tabTitle: "Account Details",
    information: "Information",
    contactName: "Contact Name",
    contactRole: "Contact Role",
    contactNumber: "Contact Number",
    croppedAreainHa: "Cropped Area",
    businessName: "Business name",
    marketingInformation: "Marketing information",
    orderNotes: "Order notes",
    email: "Email",
    phoneNumber: "Phone number",
    shortcode: "Shortcode",
    address: "Address",
    city: "City",
    postcode: "Postcode",
    country: "Country",
    contactDetails: "Contact details (order confirmation)",
    permission: "Permission to request products via",
    editInformation: "Edit information",
    search: "Search",
    show: "Show",
    entries: "entries",
    name: "Name",
    logo: "Logo",
    actions: "Actions",
  },
  login: {
    tabTitle: "Login",
    yourYagroPlatform: "Log in to YAGRO",
    yagroAdminPlatform: "Log in to YAGRO Admin",
    email: "Email",
    password: "Password",
    rememberMe: "Remember me",
    login: "Log in",
    loggingIn: "Logging in...",
    forgottenPassword: "Forgotten password?",
    tagline: "Data & Technology for Profitable Farm Businesses",
    passwordResetLinkSent:
      "A password reset link has been sent if this address is registered",
    emailAddressRequired:
      "An email address is required for us to send you your password reset email",
    failedToReachLoginServer: "Failed to reach the login server",
    anErrorOccurred: "An error occurred, please try again",
  },
  insights: {
    title: "Insights",
    tabTitle: "Insights",
  },
  alerts: {
    title: "Alerts",
    tabTitle: "Alerts",
  },
  company: {
    areasServiced: "Areas Serviced",
    farmsServiced: "Farms Serviced",
    companyInformation: "Company Information",
    users: "Users",
  },
  marketplace: {
    product: "Product:",
    amount: "Amount:",
    noAlternatives: "No alternatives",
    units: "Units:",
    deliveryDate: "Delivery date:",
    enterValidDateMsg: "Please enter a valid required by date",
    paymentDate: "Payment date:",
    comment: "Comment:",
    distributors: "Distributors:",
    allAvailable: "All available",
    noDistributorsWithThisProduct:
      "No distributors available with this product in stock",
    addAnotherQuote: "Add another quote",
    total: "Total",
    pleaseEnterUnitPrice: "Please enter a unit price",
    pleaseEnterAmount: "Please enter an amount",
    pleaseEnterValidExpiry: "Please enter a valid expiry date",
    pleaseEnterValidDelivery: "Please enter a valid delivery date",
    expiryDate: "Expiry date:",
    paymentTerms: "Payment terms:",
    pricePerUnit: "Price per unit:",
    actions: "Actions",
    requestsBy: "Requests by",
    returnToRequests: "Return to Requests",
    sendQuotes: "Send quotes",
    quantityRequired: "Quantity required:",
    requiredBy: "Required by:",
    createdDate: "Created date:",
    farmName: "Farm name:",
    deliveryLocation: "Delivery location:",
    invoiceEntity: "Invoice entity:",
    invalidAddress: "Invalid Address",
  },
  pricecheck: {
    getInTouch:
      "To learn how to improve your buying score, click OK and one of the team at YAGRO will be in touch shortly.",
    sendInvoicesTo: "Send your invoices for processing to",
    sendActivityDataTo: "Send your activity data for processing to",
  },
  products: {
    category: "Category",
    subcategories: "Subcategories",
    description: "Description",
    details: "Details",
    ingredient: "Ingredient",
    ingredients: "Ingredients",
    unit: "Unit",
    concentration: "Concentration",
    products: "Products",
    productFilters: "Product Filters",
    productList: "Product List",
    productListDescription:
      "Below is the list of products available on the website to buy and sell, with minimum specifications that any product offered on the website must comply with.",
    productName: "Name",
    otherProductDetails: "Other product details",
    viewProductDetails: "View full details",
    marketingCompany: "Marketing Company",
    mappNumber: "MAPP (Reg.) Number",
    specifications: "Specifications",
    alternatives: "Alternatives",
    replacements: "Replacements",
    images: "Images",
    crops: "Crops",
    thisProductWasReplacedBy: "This product was replaced by",
    pricingAlerts: "Pricing Alerts",
    noAlternativeProducts:
      "There are no alternative products found in our database",
    view: "View",
    compare: "Compare",
    showProductWithSameActive: "Showing products with the same active.",
    showProductWithSameActiveAndConcentration:
      "Showing products with the same active and concentration.",
    switchToExactMatchesOnActiveAndConcentration:
      "Switch to exact matches on active and concentration",
    switchToMatchesOnActiveOnly: "Switch to matches on active only",
    amountUnitConversionExplanation:
      "The following options are for calculating new amount values when a unit is changed; If not checked, any requests, quotes or product price data for the selected product will retain their amounts, independent of units.",
    quoteUnitConversionCheckbox:
      "Convert all quote amount values for this product",
    requestUnitConversionCheckbox:
      "Convert all request amount values for this product",
    productPriceDataUnitConversionCheckbox:
      "Convert all product price data amount values for this product",
    deleteProduct: "Are you sure you want to delete {productName}?",
    linkedTo: '"{productName}" is currently linked to {count} {row}.',
    chooseAlternativeProduct: "Please choose a product to merge it with:",
    updateAndDelete: "Update & Delete",
    typingProductName: "Start typing the product name",
  },
  profile: {
    title: "My Profile",
    tabTitle: "My Profile",
    firstName: "First name",
    lastName: "Last name",
    fullName: "Full name",
    email: "Email",
    phoneNumber: "Phone Number",
    address: "Address",
    postcode: "Postcode",
    city: "City",
    country: "Country",
    myProfile: "My Profile",
    updatePreferences: "Update Preferences",
    editProfile: "Edit Profile",
    changePassword: "Change Password",
    emailNotifications: "Email notifications",
    language: "Language",
    preferences: "Preferences",
    permissions: "Permissions",
    updatePermissions: "Update Permissions",
    active: "Active",
    admin: "Administrator",
    name: "Name",
    notProvided: "Not provided",
    invoiceEntities: "Invoice entities",
    revoke: "Revoke",
    devices: "Devices",
    platform: "Platform",
    client: "Client",
    deliveryLocations: "Delivery locations",
    actions: "Actions",
  },
  programmeCheck: {
    title: "Programme Check",
    performance: "Performance",
    total: "Total",
    perHectare: "Per Hectare",
    fields: "Fields",
    totalArea: "Total Area",
    averageCosts: "Average Costs",
    targetOpportunity: "Target Opportunity",
    programmeAnalysis: "Programme Analysis",
    programme: "Programme",
    partialOpportunityWarning: {
      title: "Partial Opportunity Data",
      description:
        "Your Opportunity is based on verified and validated data. We are still checking some of your fields, which may increase your Opportunity.",
    },
    summary: {
      targetOpportunity: {
        title: "Target Opportunity",
        description:
          "The Target Opportunity of the field is what you may save by optimising your active ingredients and products applied.",
      },
      percentOpportunity: {
        title: "% Opportunity",
        description:
          "The % Opportunity is the percentage of your total cost you could potentially save.",
      },
      totalCost: {
        title: "Total Cost",
        description:
          "The Total Cost is the sum of costs for all products applied to the field.",
      },
      fieldArea: {
        title: "Field Area",
        description:
          "The ground area of the field, including any that couldn't be harvested.",
      },
      harvestedArea: {
        title: "Harvested Area",
        description:
          "The area actually harvested; which may be less than the total area of the field.",
      },
      costPerHa: {
        title: "Cost per Hectare",
        description:
          "Variable costs of products applied over the field. Note that if not all fields could be harvested 100% of their area, this will reflect the cost per field area and will not be equal to the cost of production multiplied by yield (which uses the harvested area).",
      },
      yield: {
        title: "Yield",
        description:
          "Tonnes produced per hectare harvested. In this case, the source of this data is:",
      },
      cop: {
        title: "Cost of Production",
        description: "Variable costs of products applied per tonne harvested.",
      },
    },
    viewInExplorer: "View in Explorer",
    programmeAnalysis: "Programme Analysis",
    category: "Category",
    cost: "Cost",
    opportunityWithPercent: "% Opportunity",
    justChecking: "Just checking",
    productAndDate: "Product and date",
    application: "Application",
    oddDataMsg:
      "This data looks odd. We're looking into it to resolve as fast as possible for you.",
    wereOnIt: "We're on it",
    chemical: "Chemical",
    activeIngredientTitle: "Applications of {ingredient}",
    activeIngredients: "Active Ingredients",
    quantity: "Quantity",
    quantityMsg:
      "The quantity you applied over the whole field area. Click on the ingredient to see individual applications, which may have only covered part of the field.",
    marketMedian: "Market Median",
    marketMedianMsg:
      "Represents the median rate applied, based on data from all growers in the same harvest year. A benchmark for comparing your application rates of each active ingredient.",
    referenceCost: "Reference Cost",
    referenceCostMsg:
      "The weighted average price per unit based on purchases of single-ingredient products from farms. Excludes multi-ingredient product prices.",
    targetOpportunity: "Target Opportunity",
    targetOpportunityMsg:
      "Reveals cost-saving potential if your application rate is above the market median, using the difference and reference cost from single-ingredient products.",
    rateMsg:
      "The rate at which the product was applied over the area that it was. For example, 1L sprayed over 1ha is shown as 1L/ha even if the field is 2ha in total.",
    areaMsg:
      "The area of application in hectares, and as a percentage of the total field area.",
    targetOpportunityExceedsSpendMsg:
      "The target opportunity exceeds your cost, indicating you've applied more of this ingredient than the market median while paying less than the reference price in our system.",
  },
  public: {
    actionModalTitle: "Only registered users can trade on YAGRO",
    actionModalDescription:
      "In order to ensure the reliability of all participants on YAGRO Trading platform, all users must pass through a brief registration and verification process before they can begin trading. If you have already registered, click below to sign in. If you would like to register, please enter your details on our sign-up page.",
    login: "Sign in",
    register: "Register now",
    registerUrl: "https://yagro.com.br/cadastre-se/",
  },
  registration: {
    register: "Register",
    firstName: "First name",
    lastName: "Last name",
    email: "Email",
    phoneNumber: "Phone number",
    password: "Password",
    oldPassword: "Old password",
    confirmPassword: "Confirm password",
    resetPassword: "Reset password",
    updatePassword: "Update password",
    updatePasswordPolicy:
      "We have recently enhanced the security on all accounts as part of our commitment to you and your data. Unfortunately, this means you will have to update your password. Would you please complete the step below to proceed to the platform?",
    passwordMustHave: "Passwords must have:",
    termsCheck: "I accept Yagro's {termslink}.",
    termsLink: "Terms and Conditions",
    confirmTerms:
      "You must accept Yagro's terms and conditions in order to proceed.",
    eightOrMoreCharacters: "8 or more characters",
    atLeastOneUpperCase: "At least 1 upper case character",
    atLeastOneLowerCase: "At least 1 lower case character",
    atLeastOneDigit: "At least 1 digit",
    atLeastOneSymbol: "At least 1 symbol",
  },
  sidebar: {
    settingsMenuTitle: "Settings",
    productsMenuTitle: "Products",
    logout: "Log Out",
    new: "New",
    updated: "Updated",
    yagroAdmin: "Yagro Admin",
    dashboard: "dashboard",
  },
  marketwatch: {
    breadcrumb: {
      commodities: "Commodities",
    },
    menu: {
      commodities: "Commodities",
      inputs: "Inputs",
      alerts: "Alerts",
      productCatalogue: "Product Catalogue",
      marketCommentary: "Market Commentary",
    },
    filters: {
      NA: "N/A",
      quickRanges: "Quick ranges",
      prices: "Prices",
      average: "Average",
      minimum: "Minimum",
      maximum: "Maximum",
      combined: "Combined",
      last7Days: "Last 7 Days",
      last30Days: "Last 30 Days",
      last90Days: "Last 90 Days",
      last12Months: "Last 12 Months",
      yearToDate: "Year to Date",
      previousYear: "Previous Year",
      last3Years: "Last 3 years",
      nearby: "Nearby",
      to: "to",
    },
    commodities: {
      title: "MarketWatch - Commodities",
      tabTitle: "Commodities - MarketWatch",
      futures: "Futures",
      pricePerTonne: "Price per tonne",
      pricePerUnit: "Price per unit",
      average: "Average",
      netChange: "Net difference from previous day's closing price",
    },
    inputs: {
      title: "MarketWatch - Inputs",
      tabTitle: "Inputs - MarketWatch",
      noPriceDataForTheSelectedPeriod: "No price data for the selected period.",
      unknown: "Unknown",
      average: "Average",
      pricePer: "Price per",
      pricePerUnit: "Price per unit",
      unableToGetProduct: "Unable to get product",
    },
    purchases: {
      yourPurchasesLastTwelveMonths: "Your purchases in the last 12 months",
      date: "Date",
      source: "Source",
      quantity: "Quantity",
      unitPrice: "Unit price",
    },
    stats: {
      yearHighPrice: "1 Year High Price",
      yearLowPrice: "1 Year Low Price",
      latestPrice: "Latest Price",
      noPricingDataAvailable: "No pricing data available.",
    },
    alerts: {
      title: "MarketWatch - Alerts",
      tabTitle: "Alerts - MarketWatch",
      alert: "Alert",
      pricingAlerts: "Pricing alerts",
      product: "Product",
      criteria: "Criteria",
      recipient: "Recipient",
      actions: "Actions",
      absoluteValues: "Absolute Values",
      percentageChange: "Percentage Change",
      historicMinMax: "Historic Min/Max",
      whenThePriceOf: "When the price of",
      changesBy: "changes by",
      commodityPricesEndOfDayAlert:
        "Commodity alerts are based on End of Day prices and are sent once a day if the below criteria are met.",
      orMoreComparedToTheAverageForThePrevious:
        "or more compared to the average for the previous",
      orMoreComparedToTheAverageForThePastDay:
        "or more compared to the average for the past day",
      orMoreComparedToTheAverageForThePast:
        "or more compared to the average for the past",
      days: "days",
      dropsBelow: "drops below",
      dropsBelowMinimum: "Drops below minimum",
      risesAbove: "rises above",
      risesAboveMaximum: "Rises above maximum",
      nearby: "nearby",
      seenInThePastYear: "seen in past 52 weeks",
      allFarmMembers: "all farm members",
      minimum: "minimum",
      maximum: "maximum",
      oneDay: "1 day",
      sevenDays: "7 days",
      fourteenDays: "14 days",
      twentyEightDays: "28 days",
      addNewAlertByFindingAnItem: "Add a new alert by finding an item in",
      orFrom: "or from",
      noPricingAlerts: "You have no pricing alerts.",
      newAlert: "New alert",
      save: "Save",
      create: "Create",
      edit: "Edit",
      delete: "Delete",
      setAlertFor: "Set alert for",
      areYouSureToDelete: "Are you sure you want to delete this alert?",
      deleteAlertConfirmation: "Delete alert confirmation",
    },
    marketCommentary: {
      title: "MarketWatch - Market Commentary",
      tabTitle: "Market Commentary",
      lastUpdated: "Last updated",
    },
    editInputsData: {
      title: "Marketwatch - Edit Input Data",
      editPricingData: "Edit Pricing Data",
      searchProduct: "Search Product:",
      fromDate: "From:",
      toDate: "To: ",
      minPrice: "Minimum price:",
      maxPrice: "Maximum price:",
      clearFilter: "Clear Filter",
      filter: "Filter",
      product: "Product",
      source: "Source",
      date: "Date",
      price: "Price",
      action: "Action",
      errorOccurred: "An error occurred",
      priceUpdated: "Price updated",
      unableToUpdate: "Unable to update price",
      cancel: "Cancel",
      ok: "OK",
    },
  },
  content: {
    lastUpdated: "Last updated",
  },
  partnerDataTracker: {
    farms: "Farms",
    contracts: "Contracts",
    supplyContracts: "Supply Contracts",
    dataTrackerHeader: "Data Tracker",
    quickFilters: "Quick filters",
    engagementHigherThan7: "Engagement > 7.0",
    dataCompletionHigherThan60: "Data completion > 60 %",
    dataCompleted: "Data completed",
    clearFilters: "Clear filters",
    totalContracted: "Total contracted",
    totalNumbersOfFarms: "Total number of farms",
    dataSubmissionsCompleted: "Data submissions completed",
    growerEngagementScore: "Grower Engagement Score",
    averageScore: "Average score",
    ofLand: "of land",
    percentageOfTotal: "% of total",
    vsLastYear: "% vs last year",
  },
  productCatalogue: {
    title: "Product Catalogue",
  },
  units: {
    gbpBracketed: "(£)",
    gbpPerHectare: "£/ha",
  },
  userDevices: {
    platform: "Platform",
    client: "Client",
    revoke: "Revoke",
    actions: "Actions",
  },
  users: {
    title: "Users",
    tabTitle: "Users",
    show: "Show",
    entries: "entries",
    inviteUser: "Invite User",
    name: "Name",
    email: "Email",
    permissions: "Permissions",
    actions: "Actions",
    search: "Search",
  },
  table: {
    emptyText: "There are no records to show",
    emptyFilteredText: "There are no records matching your request",
    labelSortClear: "Click to clear sorting",
    labelSortDesc: "Click to sort Descending",
    labelSortAsc: "Click to sort Ascending",
  },
  terms: {
    menuTitle: "Terms",
    tabTitle: "Terms and Conditions",
    title: "Terms and Conditions",
    link: "Terms and Conditions",
  },
  tracker: {
    title: "Tracker",
    production: {
      title: "Crop Production",
    },
    costs: {
      title: "Costs",
    },
    sales: {
      title: "Sales",
    },
    budgets: {
      title: "Budgets",
      addText: "Add budgets",
      editText: "View and edit budgets",
      crops: "Crops",
      fixedCosts: {
        buttonText: "Actual fixed costs",
        editText:
          "Finalising your fixed costs confirms your actual fixed costs for the year; the rolling break even will be updated to utilise this information.",
      },
      budgetAccuracyDisclaimer:
        "To see more accurate results for fixed cost distribution, please add all crops to the variable costs table",
      cropTotalPerHaDescription:
        "When fixed cost values are calculated by area, the Crop Total becomes the weighted average of the fixed costs per hectare for all crops. The total area for these crops is",
    },
  },
  release: {
    title: "Release Notes",
    modalTitle: "What's new on YAGRO",
    productUpdates: "Go to product updates",
  },
  yAlert: {
    close: "Close",
  },
  carbon: {
    grower: "Grower",
    searchGrower: "Search for growers",
    supplyContracts: "Supply contracts",
    addContract: "Add emissions contract",
    addGrower: "Add grower",
    successMessage: "Successfully saved emissions contract",
    errorMessage: "Unable to create emissions contract",
    duplicatedErrorMessage: "Duplicated farm selection in contract details",
    detailsRequired: "Enterprise contract require at least one grower",
    createContract: "Create new emissions contract",
    contractDetails: "Contract details",
    contractIdentifier: "Contract Identifier",
    noEmissionDataInRecentYearTitle: "No data for the recent year",
    noEmissionDataInRecentYearMessage:
      "There is no emission data for the recent year, however you can see previous years history",
    noEmissionDataTitle: "No data",
    noEmissionDataMessage: "There is no emission carbon data yet",
    dataTracker: {
      farms: "Farms",
      contracts: "Contracts",
      supplyContracts: "Supply Contracts",
      dataTrackerHeader: "Data Tracker",
      quickFilters: "Quick filters",
      engagementHigherThan7: "Engagement > 7.0",
      dataCompletionHigherThan60: "Data completion > 60 %",
      dataCompleted: "Data completed",
      clearFilters: "Clear filters",
      totalContracted: "Total contracted",
      totalNumbersOfFarms: "Total number of farms",
      dataSubmissionsCompleted: "Data submissions completed",
      growerEngagementScore: "Grower Engagement Score",
      averageScore: "Average score",
      ofLand: "of land",
      percentageOfTotal: "% of total",
      vsLastYear: "% vs last year",
    },
    survey: {
      addSurvey: "Add survey",
      surveys: "Surveys",
      anErrorOccurredSaving:
        "An error occurred when attempting to save the survey, please try again.",
      requiredDataMissing:
        "Harvest year and contract identifier is required to create survey",
      successfullySavedFor: "Successfully saved survey data for",
      tooltipContractIdentifierTitle:
        "Assign Survey to Contract from available list",
      tooltipContractIdentifierText:
        "Please note unavailable contracts can be due to the contract already having a Survey associated to it or Yield information is estimated rather than actual",
      selectEnterpriseContractIdentifier:
        "Select enterprise contract identifier",
      surveyAssociated: "Survey already associated",
      yieldsNotSubmitted: "Actual yields not submitted",
      notPartOfContract: "You are not a part of any emission contract",
      surveyIsCompletedTitle: "Your survey is completed",
      surveyIsCompletedText:
        "Submitting completed survey will generate a carbon emission report. Are you sure that you want to submit ?",
    },
    emissions: {
      emissionsDashboard: "Emissions dashboard",
      carbonFootprint: "Carbon footprint",
      totalProduction: "Total production",
      nitrogenUseEfficiency: "Nitrogen use efficiency",
      landUseEfficiency: "Land use efficiency",
      breakdownOfEmissions: "Breakdown of emissions",
      averageNUETrend: "Average NUE trend",
      detailedData: "Detailed data",
      ofContractedAmount: "% of contracted amount",
      averageAcrossGrowers: "Average across",
      growers: "growers",
      usingCft: "Using CFT version",
      dataNotFromAllGrowers: "Dashboard does not contain data from all growers",
    },
  },
  formFactory: {
    formNotFound:
      "The form could not be found, please make sure you are passing either the formFile of formJson properties.",
    formInputMissingProp:
      "The form is invalid because one or more of the inputs is missing an essential property:",
    isMissing: "is missing",
    formInputNameDuplicated:
      "The form is invalid because the following input name is used more than once:",
    unknownError:
      "The form has failed the validity check but for an unknown reason.",
    submit: "Submit",
    errorLoadingForm: "There was an error loading this form.",
    clickAnywhereToTryAgain: "Please click anywhere in this box to try again.",
    apologiseForInconvenience: "We apologise for the inconvenience.",
    inputsAreInvalid: "The following inputs are invalid:",
    somethingWentWrong: "Something went wrong.",
    and: "and",
    percentComplete: "% Complete",
    suspectedInfiniteLoop: "Suspected infinite loop encountered; breaking.",
    invalidErrors: {
      required: "This field is required.",
      mustBeNumber: "This field must be a number.",
      mustBeEmail: "This field must be a valid email address.",
      minNumericalValue: "This field must be at least",
      maxNumericalValue: "This field must be no more than",
      dateMustBeAfter: "This date must be after",
      dateMustBeBefore: "This date must be before",
      mustContain: "This field must contain all of the following:",
      minLength: "This field must be at least",
      maxLength: "This field must be no more than",
      characters: "characters.",
    },
    deleteGroup: "Delete group",
  },
  analyticsReports: {
    reports: {
      title: "Reports",
      description:
        "YAGRO offers a range of reports that make it easier for you to receive valuable insights in a clear PDF.",
    },
    standardReports: {
      title: "Standard Reports",
      description:
        "A 'Harvest Report' provides a crop-level review, following your harvest and yield data submission. Using your YAGRO platform, the report contains all relevant information, including costs of production and gross margins (if sales data is available), which you can use to review your performance and display to your board, team members, and other stakeholders.",
      request: {
        title: "Request a Harvest Report",
        callToAction: "Request Your Report",
        success:
          "Your report is being created and will be sent to our team for a final quality check, we'll email it to you when it's ready.",
        fail: "Failed to generate a harvest report, please try again in a few minutes.",
      },
    },
    analyticsPro: {
      title: "Learn more about Analytics Pro",
      description: {
        primary:
          "Alternatively, you can request an elevated report more tailored to your specific needs by subscribing to Analytics Pro - the ultimate resource for informed farmers. You can choose from 5 different reports, all designed to meet a core business need.",
        secondary:
          "These personalised reports go into much more depth and include data from various sources to support our data team with providing tailored, comprehensive analysis.",
      },
      request: {
        callToAction: "Contact Account Manager*",
        actionContext:
          "* This button will send a message to your account manager",
        success:
          "Our team has been made aware of your interest in Analytics Pro and will be in touch with you shortly.",
        fail: "Failed to notify our team of your interest in Analytics Pro, please try again or email your account manager for further details.",
      },
    },
    common: {
      request: {
        noGroupSelected: "No group selected.",
        successTitle: "Success",
      },
    },
  },
};
