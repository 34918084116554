/* eslint-disable camelcase */
// store/modules/posthog.js
import Vue from 'vue';
import Vuex from 'vuex';
import posthog from 'posthog-js';

Vue.use(Vuex);

const posthogModule = {
  namespaced: true,
  state: {
    enabled: true,
    initialised: false,
  },
  mutations: {
    SET_ENABLED(state, status) {
      state.enabled = status;
    },
    SET_INITIALISED(state, status) {
      state.initialised = status;
    },
  },
  actions: {
    initialise({ state, commit, rootState }) {
      if (!state.enabled) return;

      const apiKey =
        import.meta.env.mode === 'production'
          ? 'phc_njOXYNPMICFK8Z9bGGmRJUQIKBXz1RrZROCs3NfFl5g'
          : 'phc_FNuDUHLAiiyaSVuUQKMahU5QBZPbJccM9zHeiro031t';

      try {
        posthog.init(apiKey, {
          api_host: 'https://eu.i.posthog.com',
          autocapture: true,
          loaded: () => {
            commit('SET_INITIALISED', true);
            console.log('PostHog initialised successfully.');
          },
          onerror: (error) => {
            commit('SET_INITIALISED', false);
            console.warn('PostHog failed to initialise:', error);
          },
        });
      } catch (error) {
        commit('SET_INITIALISED', false);
        console.warn('PostHog initialisation error:', error);
      }
    },
    enableTracking({ commit, dispatch }) {
      commit('SET_ENABLED', true);
      dispatch('initialise');
    },
    disableTracking({ commit }) {
      commit('SET_ENABLED', false);
      posthog.reset();
    },
    captureEvent({ state }, { event, properties }) {
      if (state.enabled && state.initialised) {
        posthog.capture(event, properties);
      }
    },
    setGroup({ state }, { group, groupID, properties }) {
      if (state.enabled && state.initialised) {
        posthog.group(group, groupID, properties);
      }
    },
    setIdentity({ state }, { id, email, name }) {
      if (state.enabled && state.initialised) {
        posthog.identify(id, { email, name });
      }
    },
  },
  getters: {
    isEnabled: (state) => state.enabled,
    isInitialised: (state) => state.initialised,
  },
};

export default posthogModule;
